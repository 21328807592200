import { IActionContext } from '@msdyn365-commerce/core';
import {
    getCheckoutState,
    ICheckoutState
} from '@msdyn365-commerce/global-state';
import { checkoutAsync, updateAsync } from '@msdyn365-commerce/retail-proxy/dist/DataActions/CartsDataActions.g';
import { getOrgUnitConfigurationAsync } from '@msdyn365-commerce/retail-proxy/dist/DataActions/OrgUnitsDataActions.g';
import {
    getTenderTypesAsync
} from '@msdyn365-commerce/retail-proxy/dist/DataActions/StoreOperationsDataActions.g';
import {
    Address,
    // CardTypeInfo,
    Cart,
    CartTenderLine,
    ChannelConfiguration,
    CommerceProperty,
    RetailOperation,
    SalesOrder,
    TenderLine,
    TenderType,
    TokenizedPaymentCard
} from '@msdyn365-commerce/retail-proxy/dist/Entities/CommerceTypes.g';
import { TelemetryStore } from '../../../common/telemetry/telemetry.store';
import { PaymentMethods } from '../citta-checkout-payment-instrument-afterpay';

const OPERATIONS = {
    PayCard: 201,
    PayGiftCertificate: 214,
    PayLoyalty: 207
};

const TENDERTYPE_NAME = {
    Afterpay: 'AfterpayCard',
    PaymentExpress: 'Cards',
    Laybuy: 'LaybuyCard'
};

// future proof card expiration required for Afterpay/Laybuy
const today = new Date();
const CARD_EXPIRATION_YEAR: number = today.getFullYear() + 2;

// const findCreditCardType = (cardTypes: CardTypeInfo[] = [], cardPrefix: string = ''): CardTypeInfo | undefined => {
//     return cardTypes.find(cardType => {
//         // Check that the card type is credit card ( InternationalCreditCard (0) or CorporateCard (3))
//         if (cardType.CardTypeValue !== 0 && cardType.CardTypeValue !== 3) {
//             return false;
//         }
//         const maskNumFrom: number = parseInt(cardType.NumberFrom || '0', 10);
//         const maskNumTo: number = parseInt(cardType.NumberTo || '0', 10);
//         const maskLength: number = cardType.NumberFrom ? cardType.NumberFrom.length : 0;
//         const cardSubStr: number =
//             cardPrefix.length > maskLength ? parseInt(cardPrefix.substr(0, maskLength), 10) : parseInt(cardPrefix, 10);
//         return maskNumFrom <= cardSubStr && cardSubStr <= maskNumTo;
//     });
// };

const findTenderIdTypeByOperationId = (tenderTypes: TenderType[], operationId: RetailOperation): string | undefined => {
    const matchedTenderType = tenderTypes.find(tenderType => tenderType.OperationId === operationId);
    if (matchedTenderType) {
        return matchedTenderType.TenderTypeId;
    }
    return;
};

const findTenderIdTypeByName = (tenderTypes: TenderType[], paymentName: string): string | undefined => {
    const matchedTenderType = tenderTypes.find(tenderType => tenderType.Name === paymentName);
    if (matchedTenderType) {
        return matchedTenderType.TenderTypeId;
    }
    return;
};

const roundNumber = (value: number) => Number(value.toFixed(2));

async function getGiftCardTenderLine(
    ctx: IActionContext,
    GiftCardId: string = '',
    Amount: number = 0,
    Currency: string = 'USD',
    GiftCardPin?: string
): Promise<CartTenderLine> {
    const tenderTypes = await getTenderTypesAsync({ callerContext: ctx, queryResultSettings: {} }).catch(error => {
        throw error;
    });
    if (!tenderTypes) {
        throw new Error('Fail to get gift card tender line');
    }
    const TenderTypeId = findTenderIdTypeByOperationId(tenderTypes, OPERATIONS.PayGiftCertificate);
    const ExtensionProperties: CommerceProperty[] = [];
    ExtensionProperties.push({
        Key: 'GIFTCARDPIN',
        Value: {
            StringValue: GiftCardPin
        }
    });
    return {
        // @ts-ignore
        // tslint:disable-next-line:prefer-type-cast
        '@odata.type': '#Microsoft.Dynamics.Commerce.Runtime.DataModel.CartTenderLine',
        // @ts-ignore
        // tslint:disable-next-line:prefer-type-cast
        'Amount@odata.type': '#Decimal',
        Currency,
        TenderTypeId,
        Amount,
        GiftCardId,
        ExtensionProperties
    };
}

async function getAfterpayTenderLine(
    tokenizedPaymentCard: TokenizedPaymentCard,
    Amount: number,
    Currency: string = 'NZD',
    ctx: IActionContext,
    afterpayOrderId?: string,
    billingAddress?: Address
): Promise<TenderLine> {
    const tenderTypes = await getTenderTypesAsync({ callerContext: ctx, queryResultSettings: {} }).catch(error => {
        throw error;
    });
    if (!tenderTypes) {
        throw new Error('Fail to get afterpay tender line');
    }

    const cardTenderLine: CartTenderLine = {
        // @ts-ignore
        // tslint:disable-next-line:prefer-type-cast
        '@odata.type': '#Microsoft.Dynamics.Commerce.Runtime.DataModel.CartTenderLine',
        // @ts-ignore
        // tslint:disable-next-line:prefer-type-cast
        'Amount@odata.type': '#Decimal',
        Currency,
        Amount,
        TenderTypeId: findTenderIdTypeByName(tenderTypes, TENDERTYPE_NAME.Afterpay),
        GiftCardId: afterpayOrderId // store orderID
    };
    if (tokenizedPaymentCard) {
        cardTenderLine.TokenizedPaymentCard = {
            ...tokenizedPaymentCard,
            CardTypeId: 'Afterpay',
            // @ts-ignore
            // tslint:disable-next-line:prefer-type-cast
            '@odata.type': '#Microsoft.Dynamics.Commerce.Runtime.DataModel.TokenizedPaymentCard',
            House: tokenizedPaymentCard.House || 'N/A',
            ...(tokenizedPaymentCard.CardTokenInfo && {
                CardTokenInfo: {
                    ...tokenizedPaymentCard.CardTokenInfo,
                    // @ts-ignore
                    // tslint:disable-next-line:prefer-type-cast
                    '@odata.type': '#Microsoft.Dynamics.Commerce.Runtime.DataModel.CardTokenInfo'
                }
            }),
            ...(billingAddress && {
                Phone: billingAddress.Phone,
                Country: billingAddress.ThreeLetterISORegionName,
                Address1: billingAddress.Street,
                City: billingAddress.City,
                State: billingAddress.State,
                Zip: billingAddress.ZipCode
            })
        };
    }

    return cardTenderLine;
}

function getExtensionPropertyValue (fieldName: string, cart: Cart) {
    const value = cart.ExtensionProperties &&
        cart.ExtensionProperties?.filter(p => p.Key === fieldName);
    if (value && value?.length > 0) {
        return value[0].Value?.StringValue || undefined;
    }

    return undefined;
}

async function placeOrder(ctx: IActionContext, checkoutDetailsObject: ICheckoutDetailsObject = {}, ourGiftToYou: boolean = false): Promise<SalesOrder> {
    if (!ctx) {
        throw new Error(`Action context cannot be null/undefined`);
    }

    const cittaTelemetry = new TelemetryStore(ctx.requestContext.app.config.cittaInstrumentationKey);

    // await getCheckoutCart(new GetCheckoutCartInput(ctx.requestContext.apiSettings), ctx).catch(error => {
    //     cittaTelemetry.logMessage(`Fail to getCheckoutCart: ${JSON.stringify(error)}`);
    //     throw error;
    // });

    // await checkoutStateDataAction(new CheckoutStateInput(ctx.requestContext.apiSettings), ctx).catch(error => {
    //     cittaTelemetry.logMessage(`Fail to get checkoutStateDataAction: ${JSON.stringify(error)}`);
    //     throw error;
    // });
    
    const checkoutState = await getCheckoutState(ctx).catch(error => {
        throw error;
    });



    const cartState = checkoutState.checkoutCart;
    let cart = cartState.cart;

    if(cart && ourGiftToYou) {
        let cartTotal = cart.SubtotalAmount || 0;
        let amountOf50s = Math.floor(cartTotal/200)
        let deliveryMessage = getExtensionPropertyValue("DELIVERYNOTES", cart);
        let additionalDeliveryMessage = `Our Gift To You: $${amountOf50s * 50} `;
        
        if (deliveryMessage && deliveryMessage.indexOf('-->') != -1) {
            deliveryMessage = deliveryMessage.split('-->')[0];
        }
        await cartState.updateExtensionProperties(
            {newExtensionProperties: [
                {Key:"DELIVERYNOTES", Value: {StringValue: `${deliveryMessage || ''} ${additionalDeliveryMessage}` }}
            ]}
        );
    }

    cart ? cittaTelemetry.logMessage(`Place order cart Id ${cart.Id}`) : cittaTelemetry.logMessage(`Place order no cart Id ${cart}`);
    const channelConfiguration = await getOrgUnitConfigurationAsync({ callerContext: ctx }).catch(error => {
        cittaTelemetry.logError(error, `Fail to getOrgUnitConfigurationAsync: ${JSON.stringify(error)}`);
        throw error;
    });

    if (!cart || !Object.keys(cart).length || !checkoutState || !channelConfiguration) {
        cittaTelemetry.logMessage(`Place order requried parameter missing`);
        throw new Error('Place order requried parameter missing');
    }

    cittaTelemetry.setCart(cart);

    if (cart.LoyaltyCardId) {
        // cart.LoyaltyCardId = checkoutState.loyaltyCardNumber;
        cart = await updateAsync({ callerContext: ctx }, cart).catch(error => {
            cittaTelemetry.logMessage(`Fail checkoutState.loyaltyCardNumber updateAsync: ${JSON.stringify(error)}`);
            cittaTelemetry.logError(error, `Fail checkoutState.loyaltyCardNumber updateAsync: ${JSON.stringify(error)}`);
            throw error;
        });
    }

    const { guestCheckoutEmail } = checkoutState;
    if (!guestCheckoutEmail) {
        cittaTelemetry.logMessage(`Fail to find guestCheckoutEmail in placeOrder function`);
        throw new Error('Fail to find guestCheckoutEmail in placeOrder function');
    }

    // Process tender lines - retry on specific error codes
    const retryCodes = [501, 502, 503];
    let retryTenderLineErrorCode: number | null = null;
    let cartTenderLines = await processTenderLines(ctx, cart, checkoutState, channelConfiguration, checkoutDetailsObject).catch(error => {
        retryTenderLineErrorCode = error.statusCode;
        cittaTelemetry.logMessage(`Fail to processTenderLines: ${JSON.stringify(error)}`);
        cittaTelemetry.logError(error, `Fail to processTenderLines: ${JSON.stringify(error)}`);
    });
    if (retryTenderLineErrorCode && retryCodes.includes(retryTenderLineErrorCode)) {
        cartTenderLines = await processTenderLines(ctx, cart, checkoutState, channelConfiguration, checkoutDetailsObject).catch(error => {
            cittaTelemetry.logMessage(`Fail to processTenderLines (retry): ${JSON.stringify(error)}`);
            cittaTelemetry.logError(error, `Fail to processTenderLines (retry): ${JSON.stringify(error)}`);
            throw error;
        });
    }

    if (!cartTenderLines || cartTenderLines.length < 1) {
        cittaTelemetry.logMessage(`No tender lines created`);
        throw new Error('No tender lines created');
    } else {
        const newTenderLine = cartTenderLines.map(x => ({
            Amount: x.Amount || 'N/A',
            TenderTypeId: x.TenderTypeId || 'N/A',
            GiftCardId: x.GiftCardId || 'N/A'
        }));
        cittaTelemetry.logMessage(`Tenderlines created ${JSON.stringify(newTenderLine)}`);
    }

    const salesOrder = await performCheckout(ctx, cittaTelemetry, guestCheckoutEmail, cartTenderLines);

    if (!salesOrder) {
        throw new Error('Fail to placeOrder: fail to checkout');
    }
    return salesOrder;
}

async function performCheckout(ctx: IActionContext, cittaTelemetry: TelemetryStore, guestCheckoutEmail: string, cartTenderLines: CartTenderLine[]): Promise<void | SalesOrder> {
    const checkoutState = await getCheckoutState(ctx).catch(error => {
        throw error;
    });
    
    const cartState = checkoutState.checkoutCart;
    // Proceed checkout
    // @ts-ignore fields tokenizedPaymentCard and receiptNumberSequence are optional
    return checkoutAsync({ callerContext: ctx }, cartState.cart.Id, guestCheckoutEmail, undefined, undefined, cartTenderLines || null, cartState.cart.Version)
        .catch(error => {
            cittaTelemetry.logMessage(`Fail checkoutAsync: ${JSON.stringify(error)}, cartVersion: ${cartState.cart.Version}`);
            cittaTelemetry.logError(error, `Fail checkoutAsync: ${JSON.stringify(error)}, cartVersion: ${cartState.cart.Version}`);
    });
}

// tslint:disable-next-line: max-func-body-length
async function processTenderLines(ctx: IActionContext, cart: Cart, checkoutState: ICheckoutState, channelConfiguration: ChannelConfiguration, checkoutDetailsObject: ICheckoutDetailsObject): Promise<CartTenderLine[] | undefined> {
    const {
        giftCardExtends,
        billingAddress
    } = checkoutState;
    const { Currency } = channelConfiguration;

    let amountDue = cart.AmountDue || 0;
    let cartTenderLines;
    const getTenderLinePromises = [];

    // Process gift cards
    if (giftCardExtends && giftCardExtends.length) {
        // giftCardExtends here to use data type that includes Pin
        giftCardExtends.filter(giftCard => giftCard.Balance)
            .reverse()
            .some(giftCard => {
                if (giftCard.Balance && amountDue > 0) {
                    const chargedAmount = Math.min(giftCard.Balance, amountDue);
                    const giftCardPinExtensionProperty = giftCard.ExtensionProperties?.find(ep => ep.Key === "GIFTCARDPIN");
                    const giftCardPin = giftCardPinExtensionProperty && giftCardPinExtensionProperty?.Value?.StringValue;
                    const creditCardTenderLinePromise = getGiftCardTenderLine(ctx, giftCard.Id, chargedAmount, Currency, giftCardPin || giftCard.Pin);
                    getTenderLinePromises.push(creditCardTenderLinePromise);
                    amountDue = roundNumber(amountDue - chargedAmount);
                }
                return amountDue === 0;
        });
    }

    // Pay the rest by credit card
    if (amountDue > 0) {
        // Check payment Method
        const paymentMethod = checkoutDetailsObject.paymentMethod;
        const cardTokenStr = checkoutDetailsObject.cardToken;
        const serviceAccountIdStr = checkoutDetailsObject.serviceAccountId;
        const uniqueCardIdStr = checkoutDetailsObject.uniqueCardId;

        // Switch according to payment method
        switch(paymentMethod) {
            case PaymentMethods.Afterpay: {
                // AFTERPAY
                const cardToken = {
                    CardToken: cardTokenStr,
                    MaskedCardNumber: '411111******1111',
                    ServiceAccountId: serviceAccountIdStr,
                    UniqueCardId: uniqueCardIdStr
                };
                const tokenizedPC = {
                    CardTokenInfo: cardToken,
                    ExpirationMonth: 10,
                    ExpirationYear: CARD_EXPIRATION_YEAR
                };
                if (!tokenizedPC) {
                    throw new Error('Failed to placeOrder: no token found - Afterpay');
                } else {
                    getTenderLinePromises.push(await getAfterpayTenderLine(
                        tokenizedPC,
                        amountDue,
                        Currency,
                        ctx,
                        checkoutDetailsObject.orderId,
                        billingAddress
                    ).catch(error => {
                        throw error;
                    }));
                }
                break;
            }
            default: {
                throw new Error('Fail to placeOrder: Invalid payment method');
            }
        }
    }

    if (getTenderLinePromises.length > 0) {
        // When payment methods is required
        cartTenderLines = await Promise.all(getTenderLinePromises).catch(error => {
            throw error;
        });

        if (!cartTenderLines || !cartTenderLines.length) {
            throw new Error('Fail to placeOrder: fail to get cart tender lines');
        }
    }

    return cartTenderLines;
}

export default placeOrder;

export interface ICheckoutDetailsObject {
    paymentMethod?: string;
    orderId?: string;
    cardToken?: string;
    serviceAccountId?: string;
    uniqueCardId?: string;
    maskedCardNumber?: string;
    cardName?: string;
    cardDateExpiry?: string;
    TxnRef?: string;
    DpstxnRef?: string;
}
